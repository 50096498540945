import * as React from 'react';
import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import TwoColumnsTwoThirdsOneThird from '../layouts/TwoColumnsTwoThirdsOneThird';
import {SingleSpeakerQueryDAO, Speaker} from '../models/Speaker';
import SpeechCard from '../components/speaker/SpeechCard';
import {Speech} from '../models/Speech';
import InternalLink from '../components/soul/navigation/InternalLink';
import SEO from '../components/SEO/SEO';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeakerDetailsPageProps {
    data: SingleSpeakerQueryDAO;
}

const SpeakerDetailsPage: React.FC<SpeakerDetailsPageProps> = ({data}) => {
    const speaker = Speaker.deserializeSingleSpeakerQuery(data);
    let sortedSpeeches = [Speech.createTBA()];
    if (speaker.speeches && speaker.speeches.length > 0) {
        sortedSpeeches = speaker.speeches.sort((a, b) => a.startDateTime.getTime() - b.startDateTime.getTime());
    }

    const schema = {
        '@context': 'https://schema.org/',
        '@type': 'Person',
        'name': `${speaker.fullName}`,
        'image': `${speaker.avatar.src}`,
        'jobTitle': `${speaker.jobTitle}`,
        'worksFor': {
            '@type': 'Organization',
            'name': `${speaker.organization}`
        }
    };
    const seoTitle = `TD23 | Listen to ${speaker.fullName} from ${speaker.organization}`;
    const seoDescription = `Listen to ${speaker.fullName} and learn how ${speaker.organization} applied ESTECO software solutions to drive the design process.`;

    return (
        <TwoColumnsTwoThirdsOneThird>
            <React.Fragment>
                <SEO description={seoDescription} title={seoTitle} schemaMarkup={schema}/>

                <div className="soul-font-size-m soul-space-stack-top-m">
                    <InternalLink to={'/speakers'}>
                        <span className='a-icon a-icon--inline a-icon--m a-icon--backward'></span>
                        Back to all the speakers
                    </InternalLink>
                </div>
                <div className="soul-space-stack-top-xl s-avatar s-avatar--xl s-avatar--primary">
                    <Img className="s-avatar__image ew-um-avatar" fluid={speaker.avatar} alt={speaker.fullName}/>
                </div>

                <h1 className="soul-space-stack-top-xl soul-font-size-xl h-text-bold">{speaker.fullName}</h1>
                <p className="soul-font-size-l">{speaker.jobTitle}</p>
                <p className="soul-space-stack-bottom-xl soul-font-size-l h-text-bold">{speaker.organization}</p>
                <div className="soul-content  soul-content--m  soul-space-stack-bottom-l" dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}/>
            </React.Fragment>

            <React.Fragment>
                <h2 className="soul-space-stack-top-3-xl soul-font-size-xl h-color-primary-bold">Talks</h2>

                {sortedSpeeches.map(speech =>
                    <div key={speech.id} className="soul-space-stack-top-l">
                        {!speech.isTBA ? <div>
                            <time dateTime={speech.formattedStartDateAndTime}>
                                {speech.formattedStartDateAndTime} - {speech.endTime} {speech.timezone}
                            </time>
                        </div> : ''}
                        <SpeechCard speech={speech}/>
                    </div>
                )}
            </React.Fragment>
        </TwoColumnsTwoThirdsOneThird>
    );
};

export const query = graphql`
    query RetrieveSpeaker($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                firstName
                lastName
                jobTitle
                avatar {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                organization
                speeches {
                    id
                    relativePath
                    childMarkdownRemark {
                        frontmatter {
                            title
                            startDate
                            startTime
                            endDate
                            endTime
                            timezone
                            day
                        }
                    }
                }
            }
        }
    }
`;

export default SpeakerDetailsPage;
