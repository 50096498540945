import * as React from 'react';
import {Speech} from '../../models/Speech';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeechCardProps {
    speech: Speech;
}

const SpeechCard: React.FC<SpeechCardProps> = ({speech}) => (
    <div className="soul-space-stack-both-xs">
        <span className="soul-font-size-m h-text-bold">{speech.title}</span>
    </div>
);

export default SpeechCard;
